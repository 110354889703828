import { Card, ViewHeader } from '@enkla-gui'

import FAQ from '@components/help/FAQ'
import React from 'react'

const frontend = require('./frontend')

export default function About() {
  return (
    <>
      <ViewHeader icon='fa-life-ring' title='Hjälp' subtitle='Om oss' />
      <Card>
        <h3 style={{ color: '#3F4254', fontWeight: '700' }}>Om oss</h3>
				<p class='mb-5' style={{ color: '#7E8299', fontWeight: '600', fontSize: '15px' }}>
					På Enkla Fastigheter strävar vi efter att förenkla din vardag som fastighetsägare med smarta, användarvänliga digitala verktyg.<br />
					Vårt mål är att göra fastighetsförvaltning både effektiv och inspirerande.<br />
					Har du frågor är du alltid välkommen att <a href='/#!/help/contact' class='text-decoration-none'>kontakta oss</a>.
				</p>
      </Card>
      <Card>
        <h3 style={{ color: '#3F4254', fontWeight: '700' }}>Tredjepartslicenser</h3>
        {Object.keys(frontend).map(key => (
          <FAQ question={`${key}`}>
            <p>
              <b>Licens</b> {frontend[key].licenses}
            </p>
            <p>
              <b>Länk</b>{' '}
              <a target='_blank' rel='noreferrer' href={frontend[key].licenseUrl}>
                {frontend[key].licenseUrl}
              </a>
            </p>
          </FAQ>
        ))}
      </Card>
    </>
  )
}
//
