import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { routes } from '@/_config'
import User from '@components/layout/Navbar/User'
import { Navbar, Sidebar, API, authenticationService, NoTengo } from '@enkla-gui'
import Modal from 'react-modal'
//eslint-disable-next-line
import { menu } from '@/_config'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'

import { PipeyProvider } from './providers/PipeyProvider';  // import the context provider
import OnboardingInProgress from './components/help/OnboardingInProgress'
registerLocale('sv', sv)
require('moment/locale/sv')

const UserContext = React.createContext({})

function App() {
	const location = useLocation()
	const [currentToken, setCurrentToken] = useState(null)
	const [user, setUser] = useState({})
	const [task, setTask] = useState(false)
	const [billable, setBillable] = useState({})

	useEffect(() => {
		authenticationService.currentToken.subscribe(x => setCurrentToken(x))
		if (authenticationService.currentTokenValue) {
			API.get('api/auth/me').then(user => setUser(user))
			API.get(`api/auth/billable`).then(billable => setBillable(billable))
			authenticationService.refresh()
			//Refresh user access_token every 30 minutes
			setTimeout(() => {
				authenticationService.refresh()
			}, 1000 * 60 * 30)
		}
	}, [])

	useEffect(() => {
		if (!currentToken) return
		API.get('api/auth/me').then(user => setUser(user))
		API.get(`api/auth/billable`).then(billable => setBillable(billable))
	}, [currentToken])

	Modal.setAppElement('#root')

	return (
		<PipeyProvider>
			<UserContext.Provider value={{ task, user, billable, setTask, setBillable, setUser }}>

				<main class={currentToken ? 'content' : 'content login-content-wrapper'}>
					{currentToken && (
						<Sidebar getBadges={async () => await API.get('api/stats/badges')} title='Fastigheter' logo='images/logo.svg' showOnboarding={user?.show_onboarding_in_sidebar} menu={menu} />
					)}
					<article class='d-flex flex-column flex-md-nowrap flex-grow-1 content-article' style={{ overflowY: currentToken ? 'auto' : 'none' }}>
						{currentToken && (
							<Navbar search={async query => await API.get('api/search', [['query', query]])}>
								<User />
							</Navbar>
						)}
						<div class={currentToken ? 'container' : ''}>
							<OnboardingInProgress />
							<ToastContainer />
							<Switch>
								<Route
									exact
									path='/'
									render={() => {
										return <Redirect to='/dashboard' />
									}}
								/>
								{routes.map(route => (
									<Route
										key={`route_${route.path}`}
										path={route.path}
										exact={route.exact || false}
										render={props => {
											if (!route.public && !authenticationService.currentTokenValue) return <Redirect to={{ pathname: '/login', state: { from: location } }} />
											return <route.component.default {...props} />
										}}
									/>
								))}
								<Route path='*'>
									<NoTengo />
								</Route>
							</Switch>
						</div>
					</article>
				</main>
			</UserContext.Provider>
		</PipeyProvider>
	)
}
export { UserContext }
export default App
