import React, { useEffect } from 'react'

import { sidebarCollapsedObserver } from '@enkla-gui'
import { useRouteMatch } from 'react-router-dom'

export default function CollapsableItem({ setActive, keyValue, link, title, icon = 'far fa-fw fa-circle', badge = false, indent = 0 }) {
  const active = useRouteMatch(link.replace('#!', ''))

  useEffect(() => {
    setActive(active)
  }, [active, setActive])

  return (
    <li class='sidebar-subitem' key={keyValue}>
      <a onClick={() => window.innerWidth < 450 && sidebarCollapsedObserver.next(false)} class={`${indent > 0 && 'ps-' + indent} sidebar-link rounded ${active ? 'active' : ''}`} href={link}>
        <div>
          <i class={icon} />
          {title}
        </div>
        {badge && badge}{' '}
      </a>
    </li>
  )
}
