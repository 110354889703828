import {
  API,
  Card,
  Input,
  Submit,
  Table,
  ViewHeader,
  useSubmit,
} from '@enkla-gui';
import React, { useEffect, useState } from 'react';

import { icons } from '@config';
import { notify } from '@/_helpers';

export default function Users() {
  const [invites, setInvites] = useState(false);
  const [users, setUsers] = useState(false);
  const {
    register,
    error,
    loading,
		getValues,
    submit: sendInvite,
  } = useSubmit({
    endpoint: 'api/invites',
    success: (res) => {
      notify.toast('Inbjudan skickad');
      getInvites();
    },
  });

  useEffect(() => {
    getUsers();
    getInvites();
  }, []);

  const getInvites = () => {
    API.get('api/invites').then((invites) => {
      setInvites(invites);
    });
  };
  const getUsers = () => {
    API.get('api/users').then((users) => {
      setUsers(users);
    });
  };
  const removeUser = (user) => {
    if (user.billable_admin === 1)
      return notify.toast('Du kan inte ta bort en administratör');
    API.delete(`api/auth/user/${user.id}`).then(() => {
			console.log('User removed')
      getUsers();
    }).catch(([error, res]) => {
			notify.toast(res.message, 'error')
		});
  };
  const removeInvite = async (invite) => {
    if (!(await notify.confirm(`Är du säker på att du vill ta bort inbjudan?`)))
      return;
    API.delete(`api/invites/${invite.id}`).then(() => {
      getInvites();
    }).catch(([error, res]) => {
			notify.toast(res.message, 'error')
		});
  };

  const onSubmit = async (data) => {
		console.log(data)
    if (
      !(await notify.confirm(
        `Är du säker på att du vill bjuda in ${getValues('email')}?`,
      ))
    )
      return;
    sendInvite();
  };

  return (
    <>
      <ViewHeader
        icon="fa-briefcase"
        title="Inställningar"
        subtitle="Användare"
      />

      <Card title="Användare" icon="fas fa-user">
        <Table
          rows={users}
          keys={[
            'name',
            'email',
            '_this>function:admin',
            `_this>button:remove:${icons.remove}`,
          ]}
          functions={{
            remove: (user) => removeUser(user),
            admin: (user) =>
              user.billable_admin === 1 ? (
                <span class="badge bg-success w-100">✓</span>
              ) : (
                <span class="badge bg-danger w-100">x</span>
              ),
          }}
        >
          <th>Namn</th>
          <th>E-Post</th>
          <th class="min">Administratör</th>
          <th class="min" />
        </Table>
      </Card>
      <div class="row">
        <Card
          buttons={{
            footer: [
              <Submit
                onSubmit={onSubmit}
                text="Skicka inbjudan"
                sending={loading}
              />,
            ],
          }}
        >
          <div class="row mb-3">
            <Input
              controls={{ register }}
              name="email"
              options={{ required: true }}
              label="E-Post"
              error={error}
            />
          </div>
        </Card>
        <Card title="Inbjudningar" icon="fas fa-envelope">
          <Table
            rows={invites}
            keys={[
              'email',
              'created_at>date:short',
              `_this>button:remove:${icons.remove}`,
            ]}
            functions={{
              remove: (invite) => removeInvite(invite),
            }}
          >
            <th>E-Post</th>
            <th>Skickad</th>
            <th class="min" />
          </Table>
        </Card>
      </div>
    </>
  );
}
