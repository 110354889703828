import { Dropdown, ViewHeaderLoader } from '@enkla-gui'

import React from 'react'
import Tippy from '@tippyjs/react'

export default function ViewHeader(props) {
	function generateBreadcrumbs(crumbs) {
		return crumbs.map((crumb, i) =><li class={`breadcrumb-item ${i === crumbs.length - 1 && 'active'}`}><i class={`fa fas ${crumb.icon} me-1`} /><a href={`${crumb.link}`}>{crumb.title}</a></li>)
	}
  return (
    <div class='component_ViewHeader'>
      {props.loading && <ViewHeaderLoader />}
      {!props.loading && (
        <>
          {(props.return && !props.breadcrumbs)&& (
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
							<li class={`breadcrumb-item`}><a href={`/`}><i class={`fa fas fa-home me-1`} /></a></li>
								<li class={`breadcrumb-item`}>
									<a href={`${props.return.link}`}>{props.return.title}</a>
								</li>
							</ol>
						</nav>
          )}
					{props.breadcrumbs &&
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class={`breadcrumb-item`}><a href={`/`}><i class={`fa fas fa-home me-1`} /></a></li>
								{props.return && <li class={`breadcrumb-item`}>
									<a href={`${props.return.link}`}>{props.return.title}</a>
								</li>}
								{generateBreadcrumbs(props.breadcrumbs)}
							</ol>
						</nav>
					}
          <div class='viewheader-header-container row'>
            <div class='col viewheader-left'>
              {props.icon && <i class={`fas fa-fw text ${props.icon}`} />}
              <div class='viewheader-text-wrapper'>
                <span class='viewheader-title'>{props.title}</span>
                <span class='viewheader-subtitle'>{props.subtitle}</span>
              </div>
            </div>
            {props.badges && (
              <div class='col viewheader-right'>
                {props.badges.map(badge => {
                  if (React.isValidElement(badge)) {
                    return badge
                  } else {
                    return (
                      <span hidden={badge.hidden} key={`viewheader_badge_${props.badges.indexOf(badge)}`} class={`badge bg-${badge.bg || 'dark'}`}>
                        {badge.icon ? <i class={`${badge.icon} mr-5`} /> : ''} {badge.text}
                      </span>
                    )
                  }
                })}
              </div>
            )}
          </div>
          {props.buttons && (
            <div class='viewheader-button-container row'>
              <div class='col'>
                <ul>
                  {props.buttons.map(button => {
                    return (
                      <li
                        key={`viewheader_button_${props.buttons.indexOf(button)}`}
                        class={`${button.hasOwnProperty('position') ? `viewheader-listitem-${button.position}` : ''}`}>
                        {button.dropdown && !button.hidden && <Dropdown {...button} />}
                        {!button.dropdown && (
                          <Tippy content={button.tip} disabled={!button.hasOwnProperty('tip') || !button.tip}>
                            <div>
                              <button
                                disabled={button.loading || button.disabled}
                                hidden={button.hasOwnProperty('hidden') ? button.hidden : false}
                                onClick={
                                  button.hasOwnProperty('callback') && !button.loading ? () => button.callback() : () => (window.location.href = button.href)
                                }
                                class={`btn btn-${button.color ?? 'gray'}`}>
                                <i class={`fas fa-fw ${button.loading ? 'fa-circle-notch fa-spin' : button.icon}`} /> {button.title}
                              </button>
                            </div>
                          </Tippy>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
