import React, { useEffect, useState } from 'react'
import { Select, authenticationService } from '@enkla-gui'

import { core } from '@config'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

export default function Register() {
  const { register, handleSubmit } = useForm()
  const history = useHistory()
  const [error, setError] = useState(null)
  const [modal, setModal] = useState(null)
  const [cookieModal, setCookieModal] = useState(null)
  const [registering, setRegistering] = useState(false)
	const [name, setName] = useState('')
	const { invite } = useParams()
  if (authenticationService.currentTokenValue !== null) history.push('/dashboard')
  var onSubmit = data => {
    setRegistering(true)
    authenticationService
      .register({ ...data, tos_accepted: true })
      .then(user => {
        history.push({
          pathname: '/login',
          message: 'Kontot registrerat, vi har skickat ett mail med länk för verifiering av e-post'
        })
      })
      .catch(([error, res]) => {
        setRegistering(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }
	useEffect(() => {
		window.fetch(`${core.server}api/invite/${invite}`).then(res => res.json()).then(res => {
			if (res) {
				setName(res.name)
			}
		})
	}, [])
  return (
    <div class='full-height login-wrapper'>
      <div class='col bicflex'>
        <div class='user-form'>
          <form onSubmit={handleSubmit(onSubmit)} class='user'>
						<input type='hidden' value={invite} {...register('invite')} />
            <div class='row'>
              <div class='col-md p-5 pb-0'>
                <div class='row'></div>
                <div class='row mb-3'>
                  <div className='col'>
                    {' '}
                    <img width='250' src={`${process.env.PUBLIC_URL}/images/logo-black.svg`} />
                    <h4 class='text'>
                      Välkommen till Enkla Fastigheter! <span class=' wave'>👋</span><br />
											<small>Du har blivit inbjuden av {name}</small>
                    </h4>
                    <p class='text-divider'>
                      <span>Registrering</span>
                    </p>
                  </div>
                </div>
                {error && <div class='alert alert-danger'>{error.message}</div>}
                <div class='row'>
                  <div class='mb-3 col-12'>
                    <label>Ditt Namn</label>
                    <input {...register('user.name', { required: true })} type='text' class='form-control form-control-register' />
                    {error?.data?.user?.name && <div class='form-inline-error'>{error.data.user.name[0]} </div>}
                  </div>
                </div>
                <div class='row'>
                  <div class='mb-3 col-12'>
                    <label>E-post</label>
                    <input {...register('user.email', { required: true })} type='text' class='form-control form-control-register' />
                    {error?.data?.user?.email && <div class='form-inline-error'>{error.data.user.email[0]} </div>}
                  </div>
                </div>
                <div class='row'>
                  <div class='mb-3 col-12'>
                    <label>Lösenord</label>
                    <input {...register('user.password')} type='password' class='form-control form-control-register' />
                    {error?.data?.user?.password && <div class='form-inline-error'>{error.data.user.password[0]} </div>}
                  </div>
                </div>
                <div class='row'>
                  <div class='mb-3 col-12'>
                    <label>Verifiera Lösenord</label>
                    <input {...register('user.password_confirmation')} type='password' class='form-control form-control-register' />
                    {error?.data?.user?.password_confirm && <div class='form-inline-error'>{error.data.user.password_confirm[0]} </div>}
                  </div>
                </div>
                <div class='col-md mb-3'>
                  {!registering && (
                    <button type='submit' class='btn register-button'>
                      Registrera
                    </button>
                  )}
                  {registering && (
                    <button type='submit' disabled class='btn register-button'>
                      <i class='fas fa-circle-notch fa-spin' /> Skapar konto...
                    </button>
                  )}
                </div>
                <div class='col mb-5'>
                  Genom att klicka på <b>Registrera</b> godkänner du våra{' '}
                  <a class='link' href={`${process.env.PUBLIC_URL}/TOS.pdf`} target='_blank'>
                    användaravtal
                  </a>
                  .
                </div>
                <div class='mb-5'>
                  <span class='small text pull-right'>
                    Har du redan ett konto?{' '}
                    <a class='link' href='#/login'>
                      Logga in istället
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class='col d-none d-md-block bicflex login-hero divider'>
        <div class='login-hero-content bicflex'>
          <img style={{ width: '75%', display: 'block' }} src={`${process.env.PUBLIC_URL}/images/register.svg`} />
        </div>
      </div>
    </div>
  )
}
