import './Pipey.scss'

import React, { useContext, useEffect, useState } from 'react'

import { NoContentBox } from '@enkla-gui'
import Overlay from './Overlay'
import { PipeySearchContext } from '@providers/PipeyProvider.js'
import Tippy from '@tippyjs/react'
import { UserContext } from '@/App'
import faq from './faq.json'

export default function Pipey() {
  const { search, setSearch } = useContext(PipeySearchContext)
  const [results, setResults] = useState(faq)
  const [active, setActive] = useState(false)
	const { user } = useContext(UserContext)

  const [tooltipAlwaysVisible, setTooltipAlwaysVisible] = useState(() => {
    return !localStorage.getItem("tooltipSeen")
  })

  useEffect(() => {
    if (search.trim().length === 0) return setResults(faq)
    dispatch(search)
    if (!active) setActive(true)
  }, [search])

  const dispatch = value => {
    const searchWords = value.toLowerCase().split(/\s+/)
    setResults(
      faq.filter(d =>
        searchWords.every(
          word =>
            d.a.toLowerCase().includes(word) ||
            d.q.toLowerCase().includes(word)
        )
      )
    )
  }

  const highlightText = text => {
    if (search === "") return <div dangerouslySetInnerHTML={{ __html: text }} />

    const regex = /(<[^>]+>|[^<]+)/g
    const parts = text.match(regex)

    const searchWords = search.split(/\s+/)
    const searchPattern = searchWords.map(word => `(${word})`).join("|")
    const regexSearch = new RegExp(searchPattern, "gi")

    const highlightedParts = parts.map((part, index) => {
      if (part.startsWith("<")) {
        return part
      } else {
        return part.replace(regexSearch, '<mark key=' + index + '>$&</mark>')
      }
    })

    const combined = highlightedParts.join("")
    return <div dangerouslySetInnerHTML={{ __html: combined }} />
  }

  const handleHelpClick = () => {
    if (tooltipAlwaysVisible) {
      localStorage.setItem("tooltipSeen", "true")
      setTooltipAlwaysVisible(false)
    }
    setActive(!active)
  }

  return (
    <>
      {active && <Overlay onClick={() => setActive(false)} />}
      <li style={{ zIndex: "200000" }} className={`nav-item dropdown ${active ? "active" : ""}`}>
        <button className="nav-link" onClick={handleHelpClick}>
          <span className="fa-stack">
            <Tippy
              content="Frågor? Klicka här för FAQ & tips."
              visible={tooltipAlwaysVisible && user.show_onboarding_in_sidebar ? true : false}
              placement="bottom-end"
            >
              <i className="fa fa-question-circle fa-stack-1x" />
            </Tippy>
          </span>
        </button>
        <ul className={`card pipey-dropdown user-dropdown dropdown-menu ${active ? "show" : ""}`}>
          <div className="component_Pipey">
            <h3>Frågor och svar</h3>
            <div className="form-group">
              <input
                value={search}
                type="text"
                className="form-control"
                placeholder="Sök..."
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <hr />
            <div className="qas">
              {results.map((f, index) => (
                <div className="qa-wrapper" key={index}>
                  <span>{highlightText(f.q)}</span>
                  <span>{highlightText(f.a)}</span>
                </div>
              ))}
              {results.length === 0 && <NoContentBox text="Din sökning gav inga träffar" />}
            </div>
          </div>
        </ul>
      </li>
    </>
  )
}
