import { Help } from '@enkla-gui';
import React, { useContext } from 'react';
import { UserContext } from '@/App';
export default function OnboardingInProgress() {

	const { task, setTask } = useContext(UserContext);
	if (!task || task.completed) return null;
	return (
		<Help type='primary' header={false}>
			<h5><i class={task.icon}></i> {task.title}</h5>
			<p>{task.description}</p>
			<p></p>
			{window.location.hash.indexOf(task.redirectOptions) !== -1 && (<>
				<a href='/#!/onboard' class='btn btn-primary me-2'>Gå tillbaka till "Kom Igång!"</a>
				<button class='btn btn-primary' onClick={() => setTask(null)}>Markera som klart</button>
				</>
			)}
			{window.location.hash.indexOf(task.redirectOptions) === -1 && (<>
				<a href={'/#!' + task.redirectOptions} class='btn btn-primary me-2'>Gå till uppgift</a>
				</>
			)}
		</Help>
	);
}
