import React from 'react'
import { sidebarCollapsedObserver } from '@enkla-gui'
import { useLocation } from 'react-router-dom'

export default function Item(props) {
  var location = useLocation()
  let active = location.pathname.includes(props.active_key)

  return (
    <li key={props.keyValue} class='mb-1 sidebar-item'>
      <a
        class={`btn btn-toggle align-items-center sidebar-single-item rounded collapsed flex-grow ${active ? 'active' : ''}`}
        href={props.link}
        onClick={() => window.innerWidth < 450 && sidebarCollapsedObserver.next(false)}>
        {props.icon && <i class={`fas fa-fw ${props.icon}`} />}
        <span> {props.title}</span>
      </a>
    </li>
  )
}
