import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, Card, Submit, Input, TextArea, CheckBox, Explination, Help } from '@enkla-gui'

export default function Portal() {
	const { register, handleSubmit, watch, reset, getValues, setValue } = useForm()
	const [error, setError] = useState(null)
	const [stub, setStub] = useState(null)
	const [enabled, setEnabled] = useState(null)
	const [sending, setSending] = useState(false)

	useEffect(() => {
		API.get('api/tenantportalconfig').then(config => {
			reset(config)
			setStub(config.stub)
			setEnabled(config.enable_portal)
		})
	}, [])

	const onSubmit = async data => {
		if (stub !== null && data.stub !== stub) {
			if (
				!(await notify.confirm(
					`Du håller på att ändra din adress till hyresgästportalen från ${stub} till ${data.stub}. Om du väljer att göra detta kommer dina hyresgäster enbart kunna nå portalen med den nya adressen`
				))
			)
				return
		}
		if (data.enable_portal !== enabled) {
			if (!(await notify.confirm(`Du håller på att ändra tillgången till hyresgästportalen, är du säker?`))) return
		}
		setSending(true)
		API.patch('api/tenantportalconfig', data)
			.then(res => {
				notify.toast('Ändringar sparade')
				setError(null)
				setSending(false)
			})
			.catch(([err, res]) => {
				setError({
					message: res.message,
					data: res.data
				})
				setSending(false)
			})
	}

	return (
		<>
			<ViewHeader icon='fa-id-badge' title='Inställningar' subtitle='Hyresgästportalen' />
			{getValues('updated_at') === null && (
				<Help type='info'>
					<p>
						Hyresgästportalen är en tjänst där dina hyresgäster kan se sina hyrda objekt, skapa felanmälningar och ställa frågor. Denna extratjänst är
						kostnadsfri.
					</p>
					<p>
						Genom att du fyller i informationen nedan och sedan sparar så kommer hyresgästportalen att aktiveras. Tjänsten går enkelt att inaktivera genom att
						bocka ur <b>Aktivera portalen</b> och sedan spara.
					</p>
					<p>
						Den adress som du anger under <b>URL till portal</b> kommer bli en del av den webbadress som hyresgästportalen nås genom. T.ex. om du anger{' '}
						<b>johans</b> så kommer dina hyresgäster att nå portalen via <b>johans.hyresgästportalen.se</b>
					</p>
				</Help>
			)}
			{!getValues('enabled') && (
				<Help temporary header={false}>
					<span>Hyresgästportalen är inaktiv.</span>
				</Help>
			)}
			<Card
				loading={!watch('id')}
				buttons={{
					footer: [<Submit onSubmit={handleSubmit(onSubmit)} text='Spara' sending={sending} />]
				}}>
				<div class='row mb-3'>
					<div class='col form-group'>
						<label>
							Aktivera portalen{' '}
							<Explination tip='Är det här aktiverat kommer dina hyresgäster kunna se sin information & kontakta dig via hyresgästportalen.' />
						</label>
						<br />
						<CheckBox controls={{ register, getValues, setValue }} name={`enabled`} options={{ required: false }} label='Aktivera portalen' />
					</div>
					<Input
						controls={{ register }}
						name='stub'
						options={{ required: false }}
						label='URL till hyresgästportalen'
						error={error}
						tip="Hur fungerar URL till hyresgästportalen?"
					/>
				</div>
				<div class='row mb-3'>
					<Input
						controls={{ register }}
						name='phone'
						options={{ required: false }}
						label='Telefonnummer (kontakt)'
						error={error}
						tip='Detta telefonnummer kommer visas för dina hyresgäster under kontakt i hyresgästportalen'
					/>
					<Input
						controls={{ register }}
						name='email'
						options={{ required: false }}
						label='E-post (kontakt)'
						error={error}
						tip='Denna e-post kommer visas för dina hyresgäster under kontakt i hyresgästportalen'
					/>
				</div>
				<div class='row'>
					<TextArea
						controls={{ register }}
						name='message'
						options={{ required: false }}
						label='Beskrivning'
						error={error}
						tip='Detta meddelande visas för dina hyresgäster tillsammans med kontaktinformation i hyresgästportalen'
					/>
				</div>
			</Card>
		</>
	)
}
