import { NoContentBox, Table, sidebarCollapsedObserver } from '@enkla-gui'
import React, { useEffect, useState } from 'react'

export default function Navbar({ search = false, children }) {
  const [collapsed, setCollapsed] = useState(sidebarCollapsedObserver.value)
  const [results, setResults] = useState([])
  const [searching, setSearching] = useState(false)
  const [query, setQuery] = useState('')

  useEffect(() => {
    sidebarCollapsedObserver.subscribe(x => setCollapsed(x))
  }, [collapsed])

  useEffect(() => {
    setResults([])
    if (query.trim().length === 0) {
      setResults([])
      setSearching(false)
      return
    }
    if (!query.length !== 0 && !searching) setSearching(true)
    const timeoutId = setTimeout(() => dispatch(query.trim()), 500)
    return () => clearTimeout(timeoutId)
  }, [query])

  const dispatch = query => {
    search(query)
      .then(response => {
        setSearching(false)
        setResults(response.hits)
      })
      .catch(error => {
        setResults([])
        setSearching(false)
      })
  }

  let toggle = () => {
    sidebarCollapsedObserver.next(!sidebarCollapsedObserver.value)
  }

  return (
    <nav class='navbar sticky-top navbar-expand-lg mb-3 component_Navbar'>
      <div class='navbar-brand' onClick={() => toggle()}>
        <i class={`fa fa-bars ${collapsed ? 'fa-rotate-90' : ''}`} />
      </div>
      {search && (
        <div class='navbar-search'>
          <input
            type='text'
            class='form-control'
            placeholder='Sök...'
            onChange={e => setQuery(e.target.value)}
            onClick={e => {
              if (query.length === 0) return
              if (!searching) setSearching(true)
              dispatch(e.target.value)
            }}
          />
          <button class='btn btn-outline-secondary' type='button'>
            <i class='fa fa-search' />
          </button>
          {results.length !== 0 && (
            <div class='card navbar-search-results' onClick={e => setResults([])}>
              <div class='search-clear-results' />
              <div class='card-body'>
                {!!results?.total?.value && (
                  <Table
                    rows={results.hits}
                    functions={{
                      type: data => (
                        <div class='icon-badge'>
                          <i class={`fas fa-fw ${data.icon}`} /> <span>{data.type}</span>{' '}
                        </div>
                      ),
                      title: data => (
                        <span>
                          <i class={'fas fa-fw ' + data.icon} /> {data.title}
                        </span>
                      ),
                      searchLink: link => {
                        return (
                          <a href={link} onClick={e => setResults([])} class='btn btn-sm btn-light'>
                            <i class={`fas fa-fw fa-folder-open`} />
                          </a>
                        )
                      }
                    }}
                    link={'@{_source.render.link}'}
                    keys={['_source.render.link>function:searchLink', '_source.render.title', '_source.render>function:type']}>
                    <th class='min'></th>
                  </Table>
                )}
                {results.total?.value === 0 && <NoContentBox text='Inga resultat' icon='fa-info-circle' />}
              </div>
            </div>
          )}
          {searching && (
            <div class='card navbar-search-results' onClick={e => setResults([])}>
              <div class='search-clear-results' />
              <div class='card-body no-padding'>
                <NoContentBox text='Söker...' icon='fa-circle-notch fa-spin' />
              </div>
            </div>
          )}
        </div>
      )}
      <div class='navbar-nav'>{children}</div>
    </nav>
  )
}
