import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import { authenticationService } from '@enkla-gui'

export default function Login() {
  const { register, handleSubmit } = useForm()
  const [error, setError] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const [email, setEmail] = useState(null)

  let { from } = location.state || { from: { pathname: '/dashboard' } }

  if (authenticationService.currentTokenValue !== null) history.push('/dashboard')

  useEffect(() => {
    var email = window.location.hash.split('?email=')[1]
    if (email !== undefined) {
      setEmail(decodeURIComponent(email))
    }
  })

  const onSubmit = data => {
    authenticationService
      .login(data.email, data.password)
      .then(user => {
        history.push(from)
      })
      .catch(result => {
        if (!result) setError('Något fick fel, försök igen')
        try {
          let [error, res] = result
          setError(res)
        } catch {
          setError(true)
        }
      })
  }
  return (
    <div class='full-height login-wrapper'>
      <div class='col bicflex'>
        <div class='user-form'>
          <form onSubmit={handleSubmit(onSubmit)} class='user'>
            <div class='row'>
              <div class='col-md p-5 pb-0'>
                <div class='row'></div>
                <div class='row mb-3'>
                  <div className='col'>
                    <img width='250' src={`${process.env.PUBLIC_URL}/images/logo-black.svg`} />
                    <h4 class='text verification-text'>Din e-postadress har verifierats, logga in och utforska möjligheterna!</h4>
                    <p class='text-divider'>
                      <span>Inloggning</span>
                    </p>
                  </div>
                </div>
                {error && <div class='alert alert-danger'>{error.message}</div>}
                {history.location.message && (
                  <div class='alert alert-success'>
                    <span style={{ color: '#000' }}>{history.location.message}</span>
                  </div>
                )}
                <div class='mb-3'>
                  <label>E-post</label>
                  <input value={email} {...register('email', { required: true })} placeholder='E-post' class='form-control form-control-register two' />
                </div>
                <div class='mb-3'>
                  <label>Lösenord</label>
                  <input {...register('password', { required: true })} type='password' placeholder='Lösenord' class='form-control form-control-register' />
                </div>

                <div class='row mb-3'>
                  <div class='col'>
                    <button type='submit' class='btn'>
                      Logga in
                    </button>
                  </div>
                </div>
                <div class='mb-5'>
                  <span class='small text pull-right'>
                    Har du glömt ditt lösenord?{' '}
                    <a class='link' href='#/forgot'>
                      Klicka här
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class='col d-none d-md-block bicflex login-hero divider'>
        <div class='login-hero-content bicflex'>
          <img style={{ width: '75%', display: 'block' }} src={`${process.env.PUBLIC_URL}/images/buildings2.svg`} />
        </div>
      </div>
    </div>
  )
}
